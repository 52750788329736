.mapbox_container {
  min-height: 35svh;
  height: 100%;
}

@media (min-width: 1024px) {
  .mapbox_container {
    min-height: unset;
    height: 100%;
  }
}
