.gradientOverlayDarkTop:before {
  /* top */
  content: '';
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  height: 10%;
  pointer-events: none;
  background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
}

@media screen and (min-width: 768px) {
  .gradientOverlayDarkTop:before {
    top: 0;
  }
}
.gradientOverlayDarkTop:after {
  /* left */
  content: '';
  position: absolute;
  top: 0;
  left: -5px;
  width: 15%;
  height: 100%;
  pointer-events: none;
  background: linear-gradient(90deg, rgba(0, 0, 0, 1) 4%, rgba(0, 0, 0, 0) 100%);
}

.gradientOverlayDarkBottom:before {
  /* bottom */
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 10%;
  pointer-events: none;
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
}
.gradientOverlayDarkBottom:after {
  /* right */
  content: '';
  position: absolute;
  bottom: 0;
  right: -5px;
  width: 15%;
  height: 100%;
  pointer-events: none;
  background: linear-gradient(270deg, rgba(0, 0, 0, 1) 4%, rgba(0, 0, 0, 0) 100%);
}

/*  Light */
.gradientOverlayLightTop:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10%;
  pointer-events: none;
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}
@media screen and (min-width: 768px) {
  .gradientOverlayLightTop:before {
    top: 0;
  }
}
.gradientOverlayLightTop:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 15%;
  height: 100%;
  pointer-events: none;
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

.gradientOverlayLightBottom:before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 10%;
  pointer-events: none;
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}
.gradientOverlayLightBottom:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 15%;
  height: 100%;
  pointer-events: none;
  background: linear-gradient(270deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}
